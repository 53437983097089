import Logo from "../assets/images/Logo.png";
import LightLogo from "../assets/images/LightLogo.png";
import BackIcon from "../assets/images/BackIcon.png";
import Call from "../assets/images/Call.png";
import Fax from "../assets/images/Fax.png";
import Email from "../assets/images/Email.png";
import Location from "../assets/images/Location.png";
import Profile from "../assets/images/Profile.png";
import DarkProfile from "../assets/images/DarkProfile.png";
import LightProfile from "../assets/images/LightProfile.png";
import ArrowRight from "../assets/images/ArrowRight.png";
import Close from "../assets/images/Close.png";
import DarkClose from "../assets/images/DarkClose.png";
import Hamber from "../assets/images/Hamber.png";
import menue from "../assets/images/menue.png";
import avatar from "../assets/images/avatar.png";
import plusIcon from "../assets/images/plusIcon.png";
import minesIcon from "../assets/images/minesIcon.png";
import service from "../assets/images/service.png";
import blur from "../assets/images/blur.jpg";
import noData from "../assets/images/NoData.png";
import loading from "../assets/images/loading2.gif";
import noImg from "../assets/images/noimg.png";
const assets = {
  getFile: (name) => assets.files[name],
  files: {
    noImg,
    loading,
    noData,
    blur,
    service,
    LightProfile,
    minesIcon,
    plusIcon,
    avatar,
    DarkClose,
    menue,
    Logo,
    BackIcon,
    Call,
    Fax,
    Email,
    Location,
    LightLogo,
    Profile,
    ArrowRight,
    Close,
    Hamber,
    DarkProfile,
  },
};

export const useAssets = () => assets;
