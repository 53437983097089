import React, { useState, useEffect } from "react";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import ReactCodeInput from "react-code-input";
import { EmailValidate, PhoneValidate } from "../../helpers/validate";
import { useAssets, useMobile } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { postWithoutToket } from "../../helpers/endpoints";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "./style.scss";

const SignUp = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [mode, setMode] = useState("");
  const [page, setPage] = useState(1);
  const [input, setinput] = useState("");
  const [secret, setSecret] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (EmailValidate(input)) {
      setMode("email");
      var body = { email: input, password: "" };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status != 201) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      } else {
        if (res.data.data.newUser) {
          setPage(2);
          setSecret(res.data.data.secret);
        } else {
          Swal.fire({
            title: "You have already registered!",
            text: "Please login or use Forgot Password",
            icon: "error",
            confirmButtonText: "ok",
          });
          navigator("/sign-in");
        }
      }
    } else {
      if (PhoneValidate(input)) {
        setMode("phone");
      } else {
        setMode("invalid");
        Swal.fire({
          title: "Invalid Email address or Phone Number!",
          text: "Please enter a valid email address or phone number",
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="sign-up col-12">
      <div className="sign-up-header d-flex flex-row align-items-center gap-2 pt-2 px-4 ">
        <img
          src={getFile("BackIcon")}
          alt="back"
          onClick={() => navigator("/sign-in")}
        />
        <a href="https://allbrainsclinic.com/" target={"_self"}>
          {" "}
          <img src={getFile("Logo")} alt="logo" />{" "}
        </a>
      </div>
      {page === 1 && (
        <div
          className={`sign-up-box d-flex flex-column ${
            !isMobile ? "justify-content-center" : ""
          } align-items-center gap-3 `}
        >
          <h2>Sign Up</h2>
          <h3>Please enter your Email Address</h3>
          <CustomInput
            fullWidth
            placeholder="Enter your Email"
            additionalClassNames={isMobile ? "signupinput" : "my-4"}
            value={input}
            onChange={(e) => {
              setinput(e.toLowerCase());
            }}
          />
          <CustomButton
            title={"Submit"}
            additionalClassNames={isMobile ? "px-5 my-4" : "px-5"}
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      )}
      {page === 2 && (
        <SubmitCode email={input} secret={secret} sendCode={handleSubmit} />
      )}
    </div>
  );
};

const SubmitCode = ({ email, secret, sendCode }) => {
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [counter, setCounter] = useState(180);
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();
  const isMobile = useMobile();

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const props = {
    inputStyle: {
      outline: "none",
      MozAppearance: "textfield",
      margin: "3%",
      width: "10%",
      fontSize: "18px",
      textAlign: "center",
      borderBottom: "2px solid #212529",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
    },
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    var data = { code: parseInt(pinCode), secret: secret, identifier: email };

    var res = await postWithoutToket("auth/verify", JSON.stringify(data));
    if (res.status != 201) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    } else {
      Cookies.set("access_token", res.data.data.token);
      navigator("/profile", { state: { email: email } });
    }
    setIsLoading(false);
  };

  const handlePinChange = (pinCode) => {
    if (pinCode === "") setIsPinCodeValid(true);
    setPinCode(pinCode);
  };

  const handleResend = () => {
    if (counter <= 0) {
      sendCode();
      setCounter(90);
    }
  };

  return (
    <div
      className={`sign-up-box d-flex flex-column align-items-center  ${
        !isMobile ? "justify-content-center gap-3" : "gap-4"
      } `}
    >
      <h2>Authentication</h2>
      <h3>Please enter the code we sent to {email}</h3>
      <ReactCodeInput
        type="number"
        fields={6}
        isValid={isPinCodeValid}
        onChange={handlePinChange}
        value={pinCode}
        {...props}
      />
      <div className="d-flex flex-row gap-5">
        <h3
          className={counter > 0 ? "disable pr-2" : "enable pr-2"}
          onClick={handleResend}
        >
          Resend Code
        </h3>
        <h3 style={{ color: "#a1813a" }}>
          {Math.floor(counter / 60)}:{counter % 60}
        </h3>
      </div>
      <CustomButton
        title={"Submit"}
        additionalClassNames="px-5"
        onClick={handleSubmit}
        loading={isLoading}
      />
    </div>
  );
};

export default SignUp;
