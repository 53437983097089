export const EmailValidate = (email) => {
  return email
    .toLowerCase()
    .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};
export const PhoneValidate = (phone) => {
  return phone.toLowerCase().match(/^[0-9\+]{1,}[0-9\-]{3,15}$/);
};

export const FaxValidate = (fax) => {
  return fax.toLowerCase().match(/^\+?[0-9]+$/);
};
