import { useState } from "react";
import "./styles.scss";

export const CustomInput = ({
  type = "text",
  value = null,
  additionalClassNames = "",
  placeholder = "",
  fullWidth = false,
  onChange = () => {},
  returnEvent = false,
  hasError = false,
  textArea = false,
  label,
  ticket = false,
  required,
  readOnly,
  select = false,
  changPass = false,
  options = [],
  isRow = false,
  pattern = null,
  ...rest
}) => {
  const [ShowPassword, setShowPassword] = useState(false);

  return textArea ? (
    <div className={`custom-input__wrapper ${fullWidth ? "w-100" : ""}`}>
      {label ? (
        <span
          className={`custom-input__label ${required ? "required" : ""}${
            ticket ? " ticket" : ""
          }`}
        >
          {label}
        </span>
      ) : null}
      <textarea
        autocomplete="off"
        onChange={(e) => onChange(returnEvent ? e : e.target.value)}
        className={`custom-input  ${fullWidth ? "w-100" : ""} ${
          hasError ? "has-error" : ""
        } ${additionalClassNames}${ticket ? " ticket__input" : ""} `}
        placeholder={placeholder}
        value={value}
        required={required}
        {...rest}
      />
    </div>
  ) : !select ? (
    <div
      className={`custom-input__wrapper ${fullWidth ? "w-100" : ""} ${
        isRow ? "d-flex flex-row align-items-center gap-3" : ""
      }`}
    >
      {label ? (
        <span
          className={`custom-input__label ${required ? "required" : ""} ${
            ticket ? " ticket" : ""
          } ${isRow ? "col-3" : ""}`}
        >
          {label}
        </span>
      ) : null}
      <div className={`d-flex flex-row ${isRow ? "col-8" : ""}`}>
        {" "}
        <input
          autocomplete="off"
          value={value}
          onChange={(e) => onChange(returnEvent ? e : e.target.value)}
          type={ShowPassword ? "text" : type}
          className={`custom-input ${fullWidth ? "w-100" : ""} ${
            hasError ? "has-error" : ""
          } ${additionalClassNames}${ticket ? " ticket__input" : ""}${
            readOnly ? "readonly" : ""
          } `}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          pattern={pattern}
          {...rest}
        />
        {type === "password" && !changPass && (
          <i
            style={{
              position: "relative",
              marginLeft: "-8%",
              marginTop: "2.5%",
              fontSize: "20px",
            }}
            className={ShowPassword ? "bi bi-eye-slash" : "bi bi-eye"}
            onClick={() => {
              setShowPassword(!ShowPassword);
            }}
          ></i>
        )}
        {changPass && (
          <svg
            style={{
              position: "relative",
              marginLeft: "-8%",
              marginTop: "4%",
              fontSize: "20px",
            }}
            width="15"
            height="15"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M9.46769 5.06376L9.65168 5.24762L2.89883 12.0005H0V9.10177L6.75285 2.3486L9.28394 4.87969L9.46769 5.06376Z"
                fill="#18324E"
              />
              <path
                d="M11.7497 3.14936L10.2641 4.63499L7.36523 1.73616L8.85086 0.250534C9.18503 -0.0835112 9.72881 -0.0835112 10.0629 0.250534L11.7498 1.93749C12.0837 2.27141 12.0837 2.81509 11.7497 3.14936H11.7497Z"
                fill="#18324E"
              />
            </g>
          </svg>
        )}
      </div>
    </div>
  ) : (
    <div
      className={`custom-input__wrapper ${additionalClassNames} ${
        fullWidth ? "w-100" : ""
      } ${isRow ? "d-flex flex-row align-items-center gap-3" : ""}`}
    >
      {label ? (
        <span
          className={`custom-input__label ${required ? "required" : ""} ${
            isRow ? "col-3" : ""
          }`}
        >
          {label}
        </span>
      ) : null}
      <div className={`d-flex flex-row ${isRow ? "col-8" : "w-100 "}`}>
        {" "}
        <select
          autocomplete="false"
          value={value}
          onChange={(e) => onChange(returnEvent ? e : e.target.value)}
          className={`custom-input w-100 ${hasError ? "has-error" : ""} ${
            readOnly ? "readonly" : ""
          }  `}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          {...rest}
        >
          {!value && (
            <option value="" disabled selected hidden>
              Choose One
            </option>
          )}
          {options.map((option) => {
            if (option.key)
              return (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              );
            else
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
          })}
        </select>
      </div>
    </div>
  );
};
