import Cookies from "js-cookie";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "./routesList";

export const AppRoutes = () => {
  var is_default = JSON.parse(localStorage.getItem("defaultPatient"));
  var default_date = new Date(
    JSON.parse(localStorage.getItem("date_default_patient"))
  );
  var date = new Date();
  const difference = date.getTime() - default_date.getTime();

  // if (is_default && default_date && difference < 2592000000) {
  //   navigator("/dashboard/" + is_default + "/service");
  // }
  return (
    <>
      <Routes>
        {ROUTES.map(({ path, Element }) => {
          return <Route path={path} key={path} element={Element} />;
        })}
        <Route
          path="*"
          element={
            Cookies.get("isLogin") ? (
              is_default ? (
                default_date ? (
                  difference < 2592000000 ? (
                    <Navigate to={"/dashboard/" + is_default + "/service"} />
                  ) : (
                    <Navigate to={"/dashboard/0"} />
                  )
                ) : (
                  <Navigate to={"/dashboard/0"} />
                )
              ) : (
                <Navigate to={"/dashboard/0"} />
              )
            ) : (
              <Navigate to={"/sign-in"} />
            )
          }
        />
      </Routes>
    </>
  );
};
