import { useEffect, useState } from "react";
import { fetchData, putData } from "../../../helpers/endpoints";
import "./ProfileInfo.scss";
import FadeLoader from "react-spinners/FadeLoader";
import { CustomInput } from "../../../components/CustomInput";
import { CustomButton } from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useMobile } from "../../../hooks";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { Layout } from "../../../components/layout";
import { PhoneValidate } from "../../../helpers/validate";
import MyModal from "./modal/Mymodal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(24, 50, 78, 0.9)",
    zIndex: "5",
  },
};

const ProfileInfo = () => {
  const [options, setOption] = useState({
    Notification: ["Email Only", "Phone Only", "Phone and Email"],
  });
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    password_confirmation: "",
    profile: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [issSaveLoading, setIssSaveLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isChangepassLoading, setIsChangepassLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const navigate = useNavigate();
  const isMobile = useMobile();

  useEffect(() => {
    async function fetchUser() {
      var res = await fetchData("user");
      if (res.status === 200) {
        setUser({
          ...user,
          email: res.data.data.email,
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          phoneNumber: res.data.data.phoneNumber,
          profile: res.data.data.profile
            ? {
                middleName: res.data.data.profile.middleName,
                preferredName: res.data.data.profile.preferredName,
                sex: res.data.data.profile.sex,
                pronoun: res.data.data.profile.pronoun,
                prefix: res.data.data.profile.prefix,
                birthday: res.data.data.profile.birthday
                  ? new Date(res.data.data.profile.birthday)
                  : "",
                gender: res.data.data.profile.gender,
                career: res.data.data.profile.career,
                address: res.data.data.profile.address
                  ? {
                      country: res.data.data.profile.address.country,
                      province: res.data.data.profile.address.province,
                      city: res.data.data.profile.address.city,
                      addressLine1: res.data.data.profile.address.addressLine1,
                      addressLine2: res.data.data.profile.address.addressLine2,
                      postalCode: res.data.data.profile.address.postalCode,
                    }
                  : "",
              }
            : {},
        });
      } else {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
      var res2 = await fetchData("user/config");
      if (res2.status === 200) {
        console.log(res2.data);
        var canf = res2.data.data.profile;
        setOption({
          ...options,
          Gender: Object.values(canf.gender),
          Province: Object.keys(Object.values(canf.cities)[0]),
          CareerPosition: Object.values(canf.careers),
          Country: Object.keys(canf.cities),
          City: canf.cities,
          Sex: Object.values(canf.sex),
          Pronouns: Object.values(canf.pronoun),
          Prefix: Object.values(canf.prefix),
        });
        console.log(options);
      } else {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
      setIsLoading(false);
    }
    fetchUser();
  }, [false]);

  const save = async () => {
    setIssSaveLoading(true);
    if (!PhoneValidate(user.phoneNumber)) {
      Swal.fire({
        title: "",
        text: "Phone number is invalid!",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIssSaveLoading(false);
      return;
    }

    const { firstName, lastName, phoneNumber, profile } = user;
    const Account = { firstName, lastName, phoneNumber };
    if (!firstName) {
      Swal.fire({
        title: "",
        text: "Please enter first name",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIssSaveLoading(false);
      return;
    }

    if (!lastName) {
      Swal.fire({
        title: "",
        text: "Please enter last name",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIssSaveLoading(false);
      return;
    }
    const resAccount = await putData("user", JSON.stringify(Account));

    if (resAccount.status !== 200) {
      Swal.fire({
        title: "",
        text: resAccount,
        icon: "error",
        confirmButtonText: "ok",
      });
      setIssSaveLoading(false);
      return;
    }

    const myProfile = {};
    for (const i in profile) {
      if (profile[i] != null && profile[i] != "") {
        myProfile[i] = profile[i];
      }
    }
    if (myProfile.birthday) {
      const resProfile = await putData(
        "user/profile",
        JSON.stringify({
          ...myProfile,
          birthday: myProfile.birthday.toISOString(),
        })
      );
      if (resProfile.status !== 200) {
        Swal.fire({
          title: "",
          text: resProfile,
          icon: "error",
          confirmButtonText: "ok",
        });
        setIssSaveLoading(false);
        return;
      }
    } else {
      const resProfile = await putData(
        "user/profile",
        JSON.stringify(myProfile)
      );
      if (resProfile.status !== 200) {
        Swal.fire({
          title: "",
          text: resProfile,
          icon: "error",
          confirmButtonText: "ok",
        });
        setIssSaveLoading(false);
        return;
      }
    }

    Swal.fire({
      title: "",
      text: "User updated successfully.",
      icon: "success",
      confirmButtonText: "ok",
    });
    setIssSaveLoading(false);
  };

  const changPassword = async () => {
    setIsChangepassLoading(true);

    if (!oldPassword) {
      Swal.fire({
        title: "",
        text: "The current password is required!",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsChangepassLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire({
        title: "",
        text: "The password and confirmation password do not match.",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsChangepassLoading(false);
      return;
    }

    if (password && password.length < 8) {
      Swal.fire({
        title: "",
        text: "Password must be at least 8 characters",
        icon: "error",
        confirmButtonText: "ok",
      });
      setIsChangepassLoading(false);
      return;
    }

    const data = {
      oldPassword: oldPassword,
      newPassword: password,
      newPasswordConfirmation: confirmPassword,
    };

    const res = await putData("user/change-password", JSON.stringify(data));

    if (res.status !== 200) {
      Swal.fire({
        title: "",
        text: res,
        icon: "error",
        confirmButtonText: "ok",
      });
    } else {
      Swal.fire({
        title: "",
        text: "Password updated successfully.",
        icon: "success",
        confirmButtonText: "ok",
      });
      setIsOpen(false);
      setOldPassword("");
      setPassword("");
      setConfirmPassword("");
    }

    setIsChangepassLoading(false);
  };

  return (
    <Layout>
      <div className="profile-info row  " dir="ltr">
        <div className="col-lg-4 col-12 d-flex flex-column gap-4 mb-5">
          <h2>Account Information</h2>
          {!isLoading ? (
            <>
              <CustomInput
                label={"Email"}
                required
                value={user.email}
                readOnly
                fullWidth
              />
              <CustomInput
                label={"Phone Number"}
                value={user.phoneNumber}
                placeholder="Enter your Phone Number"
                onChange={(e) => {
                  setUser({ ...user, phoneNumber: e });
                }}
                fullWidth
              />
              <CustomInput
                type="password"
                label={"Password"}
                required
                value={"......"}
                changPass
                onClick={() => setIsOpen(true)}
                fullWidth
              />
              <CustomInput
                select
                options={options.Notification}
                label={"Notifacation"}
              />
            </>
          ) : (
            <FadeLoader color={"#18324e"} />
          )}
        </div>
        {!isMobile && <div className="col-2" />}
        <div className="col-lg-5 col-12 d-flex flex-column gap-4">
          <h2>Personal Information</h2>
          {!isLoading ? (
            <>
              {/* {console.log(user)} */}
              <div className="d-flex col-12 flex-row gap-1">
                <CustomInput
                  label={"First Name"}
                  required
                  value={user.firstName}
                  onChange={(e) => {
                    setUser({ ...user, firstName: e });
                  }}
                  fullWidth
                />
                <CustomInput
                  label={"Middle Name"}
                  value={user.profile.middleName}
                  placeholder="Middle Name"
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, middleName: e },
                    });
                  }}
                  fullWidth
                />
              </div>
              <CustomInput
                label={"Last Name"}
                required
                value={user.lastName}
                onChange={(e) => {
                  setUser({ ...user, lastName: e });
                }}
                fullWidth
              />
              <CustomInput
                label={"Preferred Name"}
                value={user.profile.preferredName}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: { ...user.profile, preferredName: e },
                  });
                }}
                fullWidth
              />
              <span style={{ marginBottom: "-10px", fontSize: "14px" }}>
                Date Of Birth
              </span>
              <DatePicker
                value={
                  user.profile.birthday
                    ? {
                        year: user.profile.birthday.getFullYear(),
                        month: user.profile.birthday.getMonth(),
                        day: user.profile.birthday.getDate(),
                      }
                    : null
                }
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      birthday: new Date(e.year, e.month, e.day),
                    },
                  });
                }}
                inputPlaceholder="Select a day"
                shouldHighlightWeekends
                calendarClassName="responsive-calendar"
                // inputClassName="MyDatePicker"
              />

              <div className="d-flex flex-row gap-3">
                <CustomInput
                  label={"Sex"}
                  select
                  options={options.Sex}
                  fullWidth
                  value={user.profile.sex}
                  onChange={(e) => {
                    setUser({ ...user, profile: { ...user.profile, sex: e } });
                  }}
                />
                <CustomInput
                  label={"Gender Identity"}
                  select
                  options={options.Gender}
                  fullWidth
                  value={user.profile.gender ? user.profile.gender : ""}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, gender: e },
                    });
                  }}
                />
              </div>
              <div className="d-flex flex-row gap-3">
                <CustomInput
                  label={"Pronouns"}
                  select
                  options={options.Pronouns}
                  fullWidth
                  value={user.profile.pronoun}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, pronoun: e },
                    });
                  }}
                />
                <CustomInput
                  label={"Prefix"}
                  select
                  options={options.Prefix}
                  fullWidth
                  value={user.profile.prefix}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, prefix: e },
                    });
                  }}
                />
              </div>
              <CustomInput
                label={"Career Position"}
                select
                options={options.CareerPosition}
                value={user.profile.career ?? ""}
                onChange={(e) => {
                  setUser({ ...user, profile: { ...user.profile, career: e } });
                }}
              />
              <div className="d-flex flex-row gap-3">
                <CustomInput
                  label={"Country"}
                  select
                  options={options.Country}
                  fullWidth
                  value={
                    user.profile.address ? user.profile.address.country : ""
                  }
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: {
                        ...user.profile,
                        address: { ...user.profile.address, country: e },
                      },
                    });
                  }}
                />
                <CustomInput
                  label={"Province"}
                  select
                  options={options.Province}
                  fullWidth
                  value={
                    user.profile.address ? user.profile.address.province : ""
                  }
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: {
                        ...user.profile,
                        address: {
                          ...user.profile.address,
                          province: e,
                          country: "Canada",
                        },
                      },
                    });
                  }}
                />
              </div>
              <CustomInput
                label={"City"}
                select
                options={
                  user.profile.address
                    ? user.profile.address.province
                      ? Object.values(options.City)[0][
                          user.profile.address.province
                        ]
                      : []
                    : []
                }
                value={user.profile.address ? user.profile.address.city : ""}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: { ...user.profile.address, city: e },
                    },
                  });
                }}
              />
              <CustomInput
                label={"Address Line 1"}
                value={
                  user.profile.address ? user.profile.address.addressLine1 : ""
                }
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: { ...user.profile.address, addressLine1: e },
                    },
                  });
                }}
                fullWidth
              />
              <CustomInput
                label={"Address Line 2"}
                value={
                  user.profile.address ? user.profile.address.addressLine2 : ""
                }
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: { ...user.profile.address, addressLine2: e },
                    },
                  });
                }}
                fullWidth
              />
              <CustomInput
                label={"Postal Code"}
                value={
                  user.profile.address ? user.profile.address.postalCode : ""
                }
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: { ...user.profile.address, postalCode: e },
                    },
                  });
                }}
                fullWidth
              />
              {/* <span
                style={{
                  paddingTop: "15px",
                  paddingLeft: "10px",
                  fontWeight: "600",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Cookies.set("Login", false);
                  Cookies.set("access_token", null);
                  navigate("/sign-in");
                }}
              >
                Sign Out
              </span> */}
            </>
          ) : (
            <FadeLoader color={"#18324e"} />
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center gap-3 py-5">
        <CustomButton
          title={"Save Change"}
          uiType="primary"
          onClick={save}
          loading={issSaveLoading}
        />
        <CustomButton
          title={" Discard  "}
          uiType="outlined"
          additionalClassNames="px-5"
          onClick={async () => {
            navigate("dashbord/0");
          }}
        />
      </div>
      <MyModal isOpen={modalIsOpen} setisopen={setIsOpen} isLoading={isLoading}>
        {" "}
        <div className="chang-box d-flex flex-column justify-content-center align-items-center gap-3 py-4">
          <h2>Change Password</h2>
          <h3>Please enter a new Password</h3>
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e);
            }}
            label={"Current Password"}
            required
          />
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={password}
            onChange={(e) => {
              setPassword(e);
            }}
            label={"New Password"}
            required
          />
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e);
            }}
            label="Confirm New Password"
            required
          />
          <CustomButton
            title={"Submit"}
            additionalClassNames="px-5"
            onClick={changPassword}
            loading={isChangepassLoading}
          />
        </div>
      </MyModal>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className="Modal"
      >
        <div className="chang-box d-flex flex-column justify-content-center align-items-center gap-3 py-4">
          <h2>Change Password</h2>
          <h3>Please enter a new Password</h3>
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e);
            }}
            label={"Current Password"}
            required
          />
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={password}
            onChange={(e) => {
              setPassword(e);
            }}
            label={"New Password"}
            required
          />
          <CustomInput
            type="password"
            fullWidth
            placeholder="Enter your Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e);
            }}
            label="Confirm New Password"
            required
          />
          <CustomButton
            title={"Submit"}
            additionalClassNames="px-5"
            onClick={changPassword}
            loading={isChangepassLoading}
          />
        </div>
      </Modal> */}
    </Layout>
  );
};

export default ProfileInfo;
